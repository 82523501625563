<script setup lang="ts">
import { computed } from '#imports'
import TransitionFade from '../components/transition/Fade.vue'
import SkeletonDetails from '../components/Skeleton/Details.vue'

const props = defineProps<{
  hasFirstDataLoaded?: boolean
  isLoading?: boolean
}>()

const isSkeletonActive = computed(() => {
  return props.isLoading && !props.hasFirstDataLoaded
})
</script>

<template>
  <aside
    data-component="details_drawer"
    class="max-w-screen md:w-3xl bg-surface z-60 fixed bottom-0 right-0 top-0 flex h-screen w-full w-full translate-x-0 transform-gpu flex-col border-l border-neutral-200 transition"
    aria-label="details-drawer"
  >
    <TransitionFade mode="out-in">
      <div v-if="isSkeletonActive" class="animate-pulse px-8 pb-4 pt-8">
        <div class="h-5.5 w-18 rounded-lg bg-neutral-200" />
        <div class="h-5.5 w-4.5/5 mt-2 rounded-lg bg-neutral-200" />
      </div>
      <div v-else>
        <slot name="header" />
      </div>
    </TransitionFade>

    <div class="flex-1 overflow-y-auto overscroll-contain">
      <TransitionFade mode="out-in">
        <SkeletonDetails v-if="isSkeletonActive" />
        <div v-else class="h-full">
          <slot />
        </div>
      </TransitionFade>
    </div>
  </aside>
</template>
